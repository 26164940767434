import {
  UPDATE_SEARCH_PARAMS_REQUEST,
  RESET_SEARCH_PARAMS_REQUEST,
  SEARCH_CONFIG_REQUEST,
  SEARCH_CONFIG_SUCCESS,
  SEARCH_CONFIG_FAILURE,
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_SUCCESS,
  AUTOCOMPLETE_FAILURE,
  SEARCH_SORT_OPTIONS_REQUEST,
  SEARCH_SORT_OPTIONS_SUCCESS,
  SEARCH_SORT_OPTIONS_FAILURE,
  MY_SEARCHES_SUCCESS,
  SAVE_SEARCHES_SUCCESS,
} from "../actions/search";

const defaultSearchFilters = {
  Criteria: {
    forSale: true,
    forRent: false,
    propertyType: [],
    status: "Active",
  },
};

const search = (
  state = {
    filters: Object.assign({}, defaultSearchFilters),
    isConfigFetching: false,
    searchConfig: {},
    isAutoCompleteLoading: false,
    autoCompleteQuery: "",
    autoCompleteCities: [],
    autoCompleteZipCodes: [],
    autoCompleteSubdivisions: [],
    autoCompleteAddresses: [],
    isSortOptionsFetching: false,
    sortOptions: ["Newest"],
    savedSearches: null,
    previousSearches: null,
    resetSearch: false,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_SEARCH_PARAMS_REQUEST:
      return {
        ...state,
        resetSearch: false,
        lastFilters: Object.assign({}, state.filters),
        filters: Object.assign({}, action.filters),
      };
    case RESET_SEARCH_PARAMS_REQUEST:
      return {
        ...state,
        resetSearch: true,
        lastFilters: Object.assign({}, state.filters),
        filters: {
          Criteria: {
            ...defaultSearchFilters.Criteria,
            propertyType: action.defaultPropertyTypes,
          },
        },
      };
    case SEARCH_CONFIG_REQUEST:
      return {
        ...state,
        isConfigFetching: true,
        error: undefined,
      };
    case SEARCH_CONFIG_SUCCESS:
      return {
        ...state,
        isConfigFetching: false,
        searchConfig: action.response,
        error: undefined,
      };
    case SEARCH_CONFIG_FAILURE:
      return {
        ...state,
        isConfigFetching: false,
        error: action.error,
      };
    case AUTOCOMPLETE_REQUEST:
      return {
        ...state,
        isAutoCompleteLoading: true,
        autoCompleteQuery: "",
        error: undefined,
      };
    case AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        isAutoCompleteLoading: false,
        autoCompleteQuery: action.query,
        autoCompleteAddresses: action.response.Addresses,
        autoCompleteCities: action.response.Cities,
        autoCompleteZipCodes: action.response.zipCodes,
        autoCompleteSubdivisions: action.response.subdivisions,
        error: undefined,
      };
    case AUTOCOMPLETE_FAILURE:
      return {
        ...state,
        isAutoCompleteLoading: false,
        error: action.error,
      };
    case SEARCH_SORT_OPTIONS_REQUEST:
      return {
        ...state,
        isSortOptionsFetching: true,
        error: undefined,
      };
    case SEARCH_SORT_OPTIONS_SUCCESS:
      return {
        ...state,
        isSortOptionsFetching: false,
        sortOptions: action.response,
        error: undefined,
      };
    case SEARCH_SORT_OPTIONS_FAILURE:
      return {
        ...state,
        isSortOptionsFetching: false,
        error: action.error,
      };
    case MY_SEARCHES_SUCCESS:
      return {
        ...state,
        previousSearches: action.response.previousSearches.reverse(),
        savedSearches: action.response.savedSearches.reverse(),
        error: undefined,
      };
    case SAVE_SEARCHES_SUCCESS:
      return {
        ...state,
        previousSearches: action.response.previousSearches.reverse(),
        savedSearches: action.response.savedSearches.reverse(),
        error: undefined,
      };
    default:
      return state;
  }
};

export default search;
