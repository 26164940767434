import { normalize, schema } from "normalizr";
import { grouping } from "../components/utils";
import { store } from "../index.js";
import { logout } from "../actions/session";
//import { fetchProfile } from '../actions/profile';

const listingSchema = new schema.Entity(
  "Listings",
  {},
  {
    idAttribute: (listing) => listing.Id,
  }
);

const singlelistingSchema = new schema.Entity(
  "Listing",
  {},
  {
    idAttribute: (listing) => listing.Id,
  }
);

// const listingSearchSchema = new schema.Entity('ListingsSearch', {}, {
//   idAttribute: listing => listing.Id
// });

const correspondenceSchema = new schema.Entity(
  "Correspondence",
  {},
  {
    idAttribute: (correspondence) => correspondence.Id,
  }
);

const folderSchema = new schema.Entity(
  "Folders",
  {},
  {
    idAttribute: (folder) => folder.SearchName,
  }
);

const receivedDatesSchema = new schema.Entity(
  "ReceivedDates",
  {},
  {
    //idAttribute: receivedDate => receivedDate.SearchName
    idAttribute: (receivedDate) => receivedDate.SearchId,
  }
);

const activitySchema = new schema.Entity(
  "Activities",
  {},
  {
    idAttribute: (activity) => activity.Id,
  }
);

const activityIndividualSchema = new schema.Entity(
  "Activity",
  {},
  {
    idAttribute: (activity) => activity.Id,
  }
);

const transactionSchema = new schema.Entity(
  "Transactions",
  {},
  {
    idAttribute: (transaction) => transaction.Dcid,
  }
);

const contactSchema = new schema.Entity(
  "Contacts",
  {},
  {
    idAttribute: (contact) => contact.Dcid,
  }
);

const listingArraySchema = { Listings: new schema.Array(listingSchema) };

//const listingSearchArraySchema = { Listings: new schema.Array(listingSearchSchema) };

const correspondenceArraySchema = {
  Correspondence: new schema.Array(correspondenceSchema),
};

const folderArraySchema = { Folders: new schema.Array(folderSchema) };

const receivedDatesArraySchema = {
  ReceivedDates: new schema.Array(receivedDatesSchema),
};

const activityArraySchema = { Activities: new schema.Array(activitySchema) };

const activityIndividualArraySchema = {
  Activity: new schema.Array(activityIndividualSchema),
};

const transactionArraySchema = {
  Transactions: new schema.Array(transactionSchema),
};

const contactArraySchema = { Contacts: new schema.Array(contactSchema) };

export const Schemas = {
  SINGLE_LISTING: singlelistingSchema,
  LISTING: listingSchema,
  LISTING_ARRAY: listingArraySchema,
  // LISTING_SEARCH: listingSearchSchema,
  // LISTING_SEARCH_ARRAY: listingSearchArraySchema,
  CORRESPONDENCE: correspondenceSchema,
  CORRESPONDENCE_ARRAY: correspondenceArraySchema,
  FOLDER: folderSchema,
  FOLDER_ARRAY: folderArraySchema,
  RECEIVED_DATES: receivedDatesArraySchema,
  ACTIVITY: activitySchema,
  ACTIVITY_ARRAY: activityArraySchema,
  ACTIVITY_INDIVIDUAL: activityIndividualArraySchema,
  TRANSACTION: transactionSchema,
  TRANSACTION_ARRAY: transactionArraySchema,
  CONTACT: transactionSchema,
  CONTACT_ARRAY: contactArraySchema,
};

export const CALL_API = "Call API";

const callApi = (endpoint, schema, options) => {
  // Default base API route
  const API_ROOT = process.env.REACT_APP_RootURI + "/api/";

  return fetch(API_ROOT + endpoint, options).then(async (response) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (
      response.ok &&
      response.status === 200 &&
      response.url.indexOf("comments/delete") > -1
    ) {
      return {};
    } else {
      return response.json().then((json) => {
        if (!response.ok) {
          if (response.status === 401) {
            store.dispatch(logout(true));
          }
          return Promise.reject(json);
        }

        if (schema) {
          // we're not using normailzr since doesn't support relationships
          if (schema.ReceivedDates) {
            return grouping(json.ReceivedDates, "SearchId");
          } else if (json.Listing) {
            return normalize(
              { Listings: [json.Listing] },
              Schemas.LISTING_ARRAY
            );
          } else if (json.Activity) {
            return normalize(
              { Activities: [json.Activity[0]] },
              Schemas.ACTIVITY_ARRAY
            );
          }

          if (schema.Correspondence) {
            json = {
              Correspondence: json.Results,
              TotalCount: json.TotalCount,
            };
          }

          if (schema.Transactions) {
            json = { Transactions: json };
          }

          return normalize(json, schema);
        } else {
          return json;
        }
      });
    }
  });
};

const st = (store) => (next) => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { endpoint, schema, types, method, body, page } = callAPI;

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!method) {
    throw new Error("Specify a method for api call.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.sessionStorage.getItem("APIKey"),
    },
    method,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const actionWith = (data) => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return callApi(endpoint, schema, options).then(
    (response) =>
      next(
        actionWith({
          response,
          type: successType,
          page: page,
        })
      ),
    (error) =>
      next(
        actionWith({
          type: failureType,
          error:
            error.ExceptionMessage ||
            error.ErrorMessage ||
            (error[0] && error[0].ErrorMessage) ||
            "An error has occurred.",
        })
      )
  );
};

export default st;
