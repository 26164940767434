import Moment from 'moment';
import { format, isValid, parseISO } from "date-fns";

export function getFormattedCurrency(num) {
    if (!num || isNaN(num)) return "";
    var n = Math.round(num);
    return "$" + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function getFormattedNumber(num) {
    if (!num || isNaN(num)) return "";
    var n = Math.round(num);
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function toCapitalizedWords(name) {
    if (name == null)
        return "";

    name = name.toLowerCase();
    //var words = name.match(/[A-Za-z0-9 ][a-z0-9 ]*/g) || [];
    var words = name.split(' ');
    return words.map(capitalize).join(' ');
}
function capitalize(word) {
    //if (word.length === 2) return word.toUpperCase(); // e.g NJ
    return word.charAt(0).toUpperCase() + word.substring(1);
}
// convert ListingCommented to Listing Commented
export function capsCharPrependSpace(val) {
    return val.replace(/([A-Z])/g, ' $1').trim()
}
// convert 2018-11-26T00:52:00 to 11/26/2018
export function formatDate(dateVal) {
    if (!dateVal) return "";
    const date = parseISO(dateVal);
    if (!isValid(date)) return "";
    return format(date, "MM/dd/yyyy");

}
// convert 2018-11-26T00:52:00 to 11/26/2018 12:52 AM
export function formatDateTime(dateStr) {
    if (!dateStr) return "";
    return new Moment(dateStr).format('L LT').toLowerCase();
}
// convert 2018-12-10 to 12/10/2018
export function formatDateISO(dateStr) {
    if (!dateStr) return "";
    var date = new Date(dateStr);
    if (!isDate(date))
        date = formatDateISOforIE(dateStr);
    return new Moment(dateStr).format('L');
}
// same as above but adds 2 decimals to day and month
function formatDateISOforIE(dateStr) {
    var split = dateStr.split('-');
    var year = split[0];
    var month = ("0" + split[1]).slice(-2);
    var day = ("0" + split[2]).slice(-2);
    var value = year + '-' + month + '-' + day;
    return new Date(value);
}
// convert Thu Dec 13 2018 12:00:00 GMT-0500 (Eastern Standard Time) to 2018-12-13
export function dateToISOShort(date) {
    var day = date.getDate();
    var month = date.getMonth(); //Be careful! January is 0 not 1
    var year = date.getFullYear();
    var dateString = year + "-" + (month + 1) + "-" + day;
    return dateString;
}
// for datepicker to handle 12/10/2018
export function parseDate(dateStr) {
    var date = new Date(dateStr);
    if (!isDate(date))
        date = parseDateForIE(dateStr);
    return date;
}
// same as above but adds 2 decimals to day and month
function parseDateForIE(dateStr) {
    // was adding some unicode and breaking IE11
    /*eslint no-control-regex: "off"*/
    var split = dateStr.replace(/[^\x00-\x7F]/g, "").split('/');
    var year = split[2];
    var month = ("0" + split[0]).slice(-2);
    var day = ("0" + split[1]).slice(-2);
    var value = month + '/' + day + '/' + year;
    return new Date(value);
}
// convert 2018-11-26T00:52:00 to Nov 26
export function formatActivityDate(dateStr) {
    var date = new Moment(dateStr);
    return date.format('MMM D');
}
// convert 2018-11-26T00:52:00 to Nov 26 2020
export function formatActivityDateWithYear(dateStr) {
    var date = new Moment(dateStr);
    return date.isValid() ? date.format('MMM D, YYYY') : '-';
}
// convert 2018-11-26T00:52:00 to 11/26/2018
export function formatDateWithYearInStandardUSFormat(dateStr) {
    var date = new Moment(dateStr);
    return date.format('MM/D/YY');
}
// convert 2018-11-26T02:52:00 to 02:52 am
export function formatActivityTime(dateStr) {
    var date = new Moment(dateStr);
    return date.format('hh:mma');
}
// convert 2018-11-26T02:52:00 to 2:52 am
export function formatOpenHouseTime(dateStr) {
    var date = new Moment(dateStr);
    return date.format('h:mma');
}
export function formatCorrespondenceDate(dateStr) {
    var date = new Moment(dateStr);
    return date.format('MMM D, YYYY hh:mma');
}
export function formatMySearchesDate(dateStr) {
    var date = new Moment(dateStr);
    return date.format('MMM D, hh:mm a');
}
export function formatOpenHouseStart(oh) {
    if (oh) return new Moment(oh.Start).format('dddd MMM D');
    return null;
}
export function formatOpenHouseTimes(oh) {
    if (oh) {
        return (new Moment(oh.From).format('h:mma') + ' - ' + new Moment(oh.To).format('h:mma')).replace(/:00/g, '');
    }
    return null;
}
export function formatOpenHouseDates(listing, oh_ix) {
    oh_ix = oh_ix || 0;
    if (listing && listing.OpenHouses && listing.OpenHouses.Times[oh_ix]) {
        var _start = new Moment(listing.OpenHouses.Times[oh_ix].Start).format('ddd MMM D');
        return _start + ', ' + formatOpenHouseTimes(listing.OpenHouses.Times[oh_ix]);
    }
    return null;
}
export function openHouseLiveStream(listing, oh_ix) {
    oh_ix = oh_ix || 0;
    if (listing && listing.OpenHouses && listing.OpenHouses.Times[oh_ix]
        && listing.OpenHouses.Times[oh_ix].LiveStreamUrl) {
        return listing.OpenHouses.Times[oh_ix].LiveStreamUrl;
    }
    return null;
}

// needed because IE 11 needs special formatting
function isDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
            return false;
        } else {
            // date is valid
            return true;
        }
    } else {
        // not a date
        return false;
    }
}
// 2019-02-26T20:00:00.000+0000 to 2019-02-26T20:00:00.000Z - fix for IE11
export function toZeroTimeOffset(dateString) {
    return dateString.replace('+0000', 'Z');
}

// this groups array objects by property ie key
export function grouping(arr, key) {
    return (arr || []).reduce((acc, x = {}) => ({
        ...acc,
        [x[key]]: [...acc[x[key]] || [], x]
    }), {})
}

// if current logged in user is action user return 'You' else return the user name
export function getUserName(userName, userId, profile) {
    if (userId === profile.Id) return "You";
    return userName;
}

// used to convert user initials into unique color for profile icon
export function stringToColor(str) {
    return stringToHslColor(str, 50, 50);
}
// src: https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
// used to append http:// when not provided
export function appendHttp(url) {
    if (url.match(/^https?:\/\//i))
        return url;
    else return 'http://' + url;
}
// appends api token to querystring 
export const appendToken = (url) => {
    // Correct null error
    if (url === null)
        return null;

    // Check if the URL already has a token parameter
    if (url.includes("&token=") || url.includes("?token=")) {
        return url;
    }

    // Append token if it's not already present
    const token = window.sessionStorage.getItem('APIKey');

    if (url.includes("?")) {
        return url + '&token=' + token;
    }

    return url + '?token=' + token;
};

// converts 208896 to 204KB
export function formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'
    var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export function getKFormattedCurrency(num) {
    if (isNaN(num)) return "";
    var n = num;
    if (num >= 1000000) {
        n = (num / 1000000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return "$" + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "M";
    }
    else {
        //n = Math.round(num/1000);
        n = (num / 1000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return "$" + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "K";
    }
}

// offset from paging to pass to API and display
export const getOffset = (fetchPage, pageSize) => {
    var page = 1;
    if (fetchPage) page = fetchPage;
    //const offset = page === 1 ? 0 : (page - 1) * pageSize + 1;
    const offset = page === 1 ? 0 : (page - 1) * pageSize;
    return offset;
};

export function getFormattedPolygon(unFormatted) {
    if (unFormatted && unFormatted.length > 0) {

        var formattedPoly = '';

        unFormatted.map((eachPoly) => {
            if (eachPoly) {
                formattedPoly += eachPoly.lat + ' ' + eachPoly.lng + ',';
            }

            return null;
        });

        //formattedPoly += unFormatted[0].lat + ' ' + unFormatted[0].lng + ',';
        formattedPoly = formattedPoly.replace(/,\s*$/, "");

        return formattedPoly;
    }
}

export function getUnFormattedPolygon(formated) {
    if (formated && formated !== "") {

        var unformatedPoly = [];

        var eachPoly = formated.split(',');
        var internalArray = [];

        eachPoly.map((singlePoly) => {
            var LatLng = {};
            var poly = singlePoly.split(' ');

            LatLng.lat = parseFloat(poly[0]);
            LatLng.lng = parseFloat(poly[1]);

            internalArray.push(LatLng);

            return null;
        });

        unformatedPoly.push(internalArray);

        return unformatedPoly;
    }
}

// Detects if the site is being run in an iFrame.
export function isIframe() {
    return window.location !== window.parent.location;
}

// Remove a parameter from the querystring
export function removeQueryStringParams(param) {
    // Return if no querystring exists
    if (window.location.search === "") {
        return window.location.pathname;
    }

    var url = window.location.pathname.split('?')[0] + '?';
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] !== param) {
            url = url + sParameterName[0] + '=' + sParameterName[1] + '&'
        }
    }
    return url.substring(0, url.length - 1);
}

// converts "black" to #000000
export function colourNameToHex(colour) {
    var colours = {
        "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
        "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
        "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
        "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
        "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
        "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
        "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
        "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
        "honeydew": "#f0fff0", "hotpink": "#ff69b4",
        "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
        "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
        "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
        "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
        "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
        "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
        "navajowhite": "#ffdead", "navy": "#000080",
        "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
        "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
        "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
        "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
        "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
        "violet": "#ee82ee",
        "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
        "yellow": "#ffff00", "yellowgreen": "#9acd32"
    };

    if (typeof colours[colour?.toLowerCase()] !== 'undefined')
        return colours[colour.toLowerCase()];

    return false;
}

// lighten or darken color
// Lighten
// var NewColor = LightenDarkenColor("#F06D06", 20); 
// Darken
//var NewColor = LightenDarkenColor("#F06D06", -20); 
export function lightenDarkenColor(col, amt) {

    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

//remove obj properties e.g removeProperties(field, 'id', 'section')
export const removeProperties = (object, ...keys) =>
    Object.entries(object).reduce((prev, [key, value]) => ({ ...prev, ...(!keys.includes(key) && { [key]: value }) }), {})

export const getSizes = (isMobileParam = 480) => {
    const width = window.screen.width;
    const height = window.screen.height;
    const isMobile = width < isMobileParam;
    const isDesktop = width > 991;
    const isLandscapeTablet = width >= 768 && width <= 991;
    const greaterLandscapeTablet = width > 991;
    const under1200 = width < 1200;
    const underLandscapeTablet = width < 768;
    const wrapperHeight = width < 768 ? (height - 108) + 'px' : (height - 110) + 'px';

    return { width, isMobile, isDesktop, isLandscapeTablet, greaterLandscapeTablet, under1200, underLandscapeTablet, wrapperHeight };
}